import Layout from '../components/MyLayout'
import { Result, Button } from 'antd'

export default () => (
    <Layout>
        {/* <Result
            icon={<img src="/404.png" />}
            subTitle="很抱歉，你访问的页面不存在!"
            extra={<a href="/"><Button type="primary">回到首页</Button></a>}
        /> */}
        <Result
            status="404"
            title="404"
            subTitle="很抱歉，你访问的页面不存在!"
            extra={<a href="/"><Button type="primary">回到首页</Button></a>}
        />
    </Layout>
)
